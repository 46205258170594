.footer-navigation ul{margin:0;padding:10px 0 0 0;}
.footer-navigation__item{text-transform:uppercase;position:relative;text-align:center;margin-bottom:10px;}
.footer-navigation__item:after{width:1px;height:15px;content:" ";left:-5px;top:4px;background:#a9a8a9;position:absolute;display:block;}
.footer-navigation__item:first-child{margin-left:0;}
.footer-navigation__item:first-child:after{background:transparent;}
@media (min-width: 768px){
	.footer-navigation__item{margin-bottom:0;}
}
.footer-navigation__item,.footer-navigation__link{color:#a9a8a9;font-weight:bold;position:relative;font-size:13px;text-align:center;}
@media (min-width: 768px){
	.footer-navigation__item,.footer-navigation__link{font-size:13px;text-align:left;display:inline-block;padding:0 7.5px;}
}