.verify-header-text {
  padding-right: 20px;
  font-size: 60px;
  line-height: 70px;
  font-family: $headings-font-family;
  text-transform: uppercase;
  @include media-breakpoint-down(md) {
    font-size: 40px;
    line-height: 50px;
  }
}

.verify-header-logo {
  width: 50%;
}

.verify-container-wrapper {
  margin: 10%;
  border-radius: 10px;
  border: 23px solid $clr-verify;
  background-color: $clr-verify;
  font-family: $font-family-sans-serif;

  .verify-container {
    border-radius: 10px;    
    background-color: #FFFFFF;
    padding-bottom: 20px;
    padding-top: 20px;

    h2 {
      font-family: $medium-font-family;
    }

    h3 {
      font-family: $font-family-sans-serif;
    }

    .verify-member-benefits {
      /*font-size: 64px; 
      font-family: $font-family-sans-serif;
      line-height: 80px;*/
    }

    p {
      /*font-size: 34px;
      line-height: inherit;*/
    }

    .offers {
      /*font-size: 30px;
      padding: 0 100px 0 100px;*/
    }

    .btn {
      color: $white;
      background-color: $clr-verify;
    }
  }
}

.verify-background {
  background-image: url('/img/kiosk-bg-img.png');
  background-size: cover;
  height: 100vh;
}

