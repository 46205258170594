$enable-responsive-font-sizes: true;

@import "components/variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "components/header";
@import "components/footer";
@import "components/createpassword";
@import "components/donations";
@import "components/verify";
@import "components/gift";
@import "components/bookings";
@import "components/mixins";
@import "components/utilities";

.form-check-input {
  margin-top: 0.4rem;
  margin-left: -1.3rem;
}

input[type="radio"], input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

fieldset:not(:last-of-type) {
  padding-bottom: 16px;
}

legend {
  h2 {
    border-bottom: 1px solid;
    padding-top: 15px;
  }
}

p {
  line-height: 23px;
  margin-bottom: 18px;
  margin: 0 0 12.5px;
}

.btn {
  padding: 6px 15px;
  font-family: $btn-font-family;
  font-weight: normal;
  font-style: normal;
  width: 150px;
  border-radius: 5px;
  color: $white;
  font-size: 16px;

  &:hover, &:focus, &:active, &.active {
    color: $white;
  }

  .left {
    padding-right: 10px;
  }
  .right {
    padding-left: 10px;
  }

}

.btn-back {
  color: #333333;
  border: 2px solid #333333;

  &:hover, &:focus, &:active, &.active {
    color: #333333;
  }

}

/* Used in places where we already have navigation buttons on the page
.btn-action {
  color: #FFFFFF;
  border: 2px solid #333333;

  &:hover, &:focus, &:active, &.active {
    color: #333333;
  }

}*/

.btn.disabled {
    color: #000000;
    background-color: #c3c2c2;
    border-color: #c3c2c2;
}

.site-footer--legal {
  background: #ffffff;
  padding: 20px 0;
}

.site-logo-footer {
  &:after {
    clear: both;
  }
  display: block;
  text-align: center;
  img {
    margin: 0 auto;
    width: 170px;
  }
}

@media (min-width: 768px) {
  .site-logo-footer img {
    margin: 0;
    float: right;
  }
}

.intro {
  margin-top: 60px;
}

#formInstructions {
  padding-bottom: 12px;
}

/* DOB */
#dob_day, #dob_month {
  flex: 0 1 55px;
}

#dob_year {
  flex: 0 1 60px;
}

/* Additional info */
#step2 fieldset {
  margin-bottom: 5%;
}

[id^="step"] a {
    text-decoration: underline;
}

/* Payment */
#step3 .intro, #payments .intro {
  border-bottom: 2px solid #333333;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

#step3 fieldset, #payments fieldset {
  margin-bottom: 5%;
}

.checkout-items {
  border-bottom: 1px solid #333333;
  padding: 20px 0px 10px 0px;
  text-transform: uppercase;
}

.checkout-items-total {
  @extend .bold-font;
  font-size: 1.1rem;
}

.cc_expiration {
  label {
    display: block;
  }
}

.credit-card-expiry {
  width: 4em;
  display: inline-block;
}

.cc-csc {
  width: calc(4ch + 2ch + 1ch + 2px);
}

#payment_frequency_discount {
  display: inline;
  color: red;
}

.checkout_total {
  border-top: 1px solid;
  max-width: 50px;
}

.receipt {
  margin-top: 20px;
  margin-bottom: 20px;
  span {
    border: 1px solid;
    padding: 5px 10px 5px 10px;
  }
}

.backLogo {
  position: relative;
  background: #ffffff;
}

#breadcrumb {
  font-size: 14px;
  font-weight: 700;
  background: none;
  border: 0;

  a {
    color: black;
  }
}

#other_amount_div {
  label {
    @include media-breakpoint-up(sm) {
      padding-right: 10px;
    }
  }
}

.img-bg-wrapper {
  position: relative;
  background: black;
}

.img-bg-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-bg-wrapper .overlay {
  color: white;
  position:absolute;
  display: flex;
  padding-top: 46px;
  overflow: hidden;
  flex-direction: column;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.shadow {
  box-shadow: 16px 16px 48px #00000019;
  border-radius: 3px;
}

.navigation_buttons {
  margin: 5% 5% 5% 5%;
}

.discount_description {
  background-color: #f8d7d8;
  padding: 10px;
}

/* Email verification step 1: Hide the success image as it is misleading */
span.af-ev-icon-success {
  background-image: none;
}