
#tier-error {
  @extend .no-gutter-no-row;
}

.payment_types {
  border: 1px solid #00000029;
  margin-top:35px;
  padding-bottom: 10px;
  
  @include media-breakpoint-up(xl) {
    margin-left:7.5px;
  }
  @include media-breakpoint-down(xl) {
    padding-left:7.5px;
  }

  legend {
    position: absolute;
    margin-top: -35px;
    margin-left: -7.5px;
  }

  hr {
    @include media-breakpoint-up(xl) {
      margin-left: -15px;
      margin-right: -7.5px
    }
    @include media-breakpoint-down(xl) {
      margin-left: -7.5px;
    }
  }
}

.payment_types_cc {
  padding-left: 1.25rem;
  padding-top: 10px;
}

.payment_types_paypal {
  padding-right: 7.5px;
  padding-top: 10px;
}

.payment_icons {
  width: 130px;
}

