/* Password strength indicator */
.color-indicators {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 0.2rem;
    margin-top: 5px;

    span {
        height: 5px;
    }

}

#colourInitial.valid { background-color: red }
#colourTooWeak.valid { background-color: orange }
#colourWeak.valid { background-color: yellow }
#colourStrong.valid { background-color: green }

/* Password matching colours */
.passwordsMatched {
    color: green;
}

.passwordsDidNotMatch {
    color: red;
}

/* Gets enabled with JS when the password and confirm values match */
#setPassButton {
    display: none;
    margin-top: 5px;
    margin-bottom: 16px;
}