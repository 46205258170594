.site-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 0;
  -webkit-transition: margin-left 0.3s linear;
  -moz-transition: margin-left 0.3s linear;
  transition: margin-left 0.3s linear;
}

#skip-link {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}

.site-header {
  min-height: 85px;
  position: relative;

  @include media-breakpoint-up(md) {
    height: auto;
  }

  .site-header__left-container-row {
    @include media-breakpoint-up(md) {
      min-height: 112px;
    }
    @include media-breakpoint-up(lg) {
      height: 155px;
    }
  }

  .site-header__container {
    position: relative;
  }

  .site-header__left {
    padding: 22px 0 22px 22px;
    position: relative;
  
    .site-logo {
      position: relative;
      z-index: 2;
      height: 40px;
  
      @include media-breakpoint-up(md) {
        height: auto;
        max-width: 187px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 230px;
        margin-top: 30px;
        left: -7px;
      }

    }
  }

  .site-header__right {
    height: 242px;
    display: flex;
    flex-direction: column;
  }

}

.design-only--header-left-black {
  background: #000000;
  top: 0;
  right: 100%;
  width: 999em;
  height: 85px;
  content: " ";
  display: block;
  position: absolute;
  z-index: 0;
  margin-right: -25px;

  @include media-breakpoint-up(md) {
    height: 112px;
  }

  @include media-breakpoint-up(lg) {
    height: 155px;
  }
}

.design-only--header-height {
  height: 85px;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: 112px;
  }

  @include media-breakpoint-up(lg) {
    height: 155px;
  }
}

.design-only--header-black-divider {
  top: 0;
  width: 0;
  height: 0;
  right: -25px;
  transform: scale(0.9999);
  border-top: 85px solid transparent;
  border-bottom: 0px solid transparent;
  border-left: 25px solid #000000;
  content: " ";
  display: block;
  position: absolute;
  z-index: 1;

  @include media-breakpoint-up(md) {
    border-top-width: 112px;
  }

  @include media-breakpoint-up(lg) {
    border-top-width: 155px;
    right: -50px;
    border-left: 50px solid #000000;
  }
}

body.body-color__define--home {
  .color {
    border-bottom-color: #7DB0E1;
    color: $clr-home;
  }
  .body-color__border-top {
    border-top-color: #7DB0E1;
  }
  .body-color__background, .body-color__text {
    background-color: #7DB0E1;
  }
  .body-color__background--lighter {
    background: #7DB0E1;
  }
  .btn-color {
    background-color: $clr-home;
    &:hover, &:focus, &:active, &.active {
      background-color: $clr-home-hover;
    }
  }
}

body.define--student {
  .color {
    color: $clr-student;
  }
  .background-color {
    background-color: $clr-student;
  }
  .btn-color {
    background-color: $clr-student;
    &:hover, &:focus, &:active, &.active {
      background-color: $clr-student-hover;
    }
  }
  .donation_buttons input[type='radio'], .donation_type_buttons input[type='radio'] {
    + label {
      border: 1px solid $clr-student;
    }
    &:checked + label {
      background-color: $clr-student;
      border: 1px solid $clr-student;
    }
  }
  .donation_buttons input[type='radio'] {
    &:hover + label {
      background-color: $clr-student;
      border: 1px solid $clr-student;
    }
  }
  .donation_type_buttons input[type='radio'] {
    + label {
      color: $clr-student;
    }
  }
  .custom-control-inline input[type='radio'] { /* Bookplate background */
    &:checked + label {
      background-color: $clr-student;
      color: #ffffff;
    }
  }
}

body.define--family {
  .color {
    color: $clr-family;
  }
  .background-color {
    background-color: $clr-family;
  }
  .btn-color {
    background-color: $clr-family;
    &:hover, &:focus, &:active, &.active {
      background-color: $clr-family-hover;
    }
  }
  .donation_buttons input[type='radio'], .donation_type_buttons input[type='radio'] {
    + label {
      border: 1px solid $clr-family;
    }
    &:checked + label {
      background-color: $clr-family;
      border: 1px solid $clr-family;
    }
  }
  .donation_buttons input[type='radio'] {
    &:hover + label {
      background-color: $clr-family;
      border: 1px solid $clr-family;
    }
  }
  .custom-control-inline input[type='radio'] { /* Bookplate background */
    &:checked + label {
      background-color: $clr-family;
      color: #ffffff;
    }
  }
}

body.define--cultural {
  .color {
    color: $clr-cultural;
  }
  .background-color {
    background-color: $clr-cultural;
  }
  .btn-color {
    background-color: $clr-cultural;
    &:hover, &:focus, &:active, &.active {
      background-color: $clr-cultural-hover;
    }
  }
  .donation_buttons input[type='radio'], .donation_type_buttons input[type='radio'] {
    + label {
      border: 1px solid $clr-cultural;
    }
    &:checked + label {
      background-color: $clr-cultural;
    }
  }
  .donation_buttons input[type='radio'] {
    &:hover + label {
      background-color: $clr-cultural;
    }
  }
  .custom-control-inline input[type='radio'] { /* Bookplate background */
    &:checked + label {
      background-color: $clr-cultural;
      color: #ffffff;
    }
  }
}

body.define--access {
  .color {
    color: $clr-access;
  }
  .background-color {
    background-color: $clr-access;
  }
  .btn-color {
    background-color: $clr-access;
    &:hover, &:focus, &:active, &.active {
      background-color: $clr-access-hover;
    }
  }
  .donation_buttons input[type='radio'], .donation_type_buttons input[type='radio'] {
    + label {
      border: 1px solid $clr-access;
    }
    &:checked + label {
      background-color: $clr-access;
    }
  }
  .donation_buttons input[type='radio'] {
    &:hover + label {
      background-color: $clr-access;
    }
  }
  .custom-control-inline input[type='radio'] { /* Bookplate background */
    &:checked + label {
      background-color: $clr-access;
      color: #ffffff;
    }
  }
}

.row {
  /* Override the one set in bootstrap */
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.container {
  /* Override the one set in bootstrap */
  margin-right: auto;
  margin-left: auto;
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.expanding-gutter .row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

@media (min-width: 1200px) {
  .expanding-gutter .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

section {
  padding: 20px 15px 0 15px;
  background: #ffffff;
  overflow: hidden;
  margin-bottom: 15px;
}

@media (min-width: 1200px) {
  section {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .header-banner--wrapper {
    height: 232px;
  }
}


h1, h2 {
  text-transform: uppercase;
  span {
    position: relative;
    padding-bottom: 24.5px;

    + img {
      border-radius: 50%;
	    padding: 10px;
	    max-height: 100%;
      margin-left: 10px;
      
      @include media-breakpoint-up(md) {
        padding: 15px;
      }
    }

    &:after {
      position: absolute; 
      left: 0; 
      bottom: 0; 
      width: 100%; 
      height: 1px; 
      border-bottom: 2px solid;
      content: "";
    }
  }
}

