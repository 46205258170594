#donations_block {
    .checkbox-label {
        font-size: 0.8rem;
    }
}

#donation_bookplate_images {
	margin-bottom: 0;
}

.donation_buttons {
    width: 140px;
    margin-right: auto;
}
.donation_type_buttons {
    width: 260px;
}

.donation_buttons, .donation_type_buttons {
    @include media-breakpoint-up(xl) {
  
      &:last-child {
        margin-right: 0;
      }
    }
  
    label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
}

.donation_buttons input[type='radio'], .donation_type_buttons input[type='radio'] {
	position: absolute;
	opacity: 0;

	+ label {
		@include media-breakpoint-down(sm) {
		  width: 100%;
		  text-align: center;
		}
		box-sizing: border-box;
		background-color: #FFFFFF;
		cursor: pointer;
		padding: 8px 14px;
		white-space: nowrap;
		color: $black;
		font-weight: bold;
		box-shadow: none;
		width: 100%;
		height: 80px;
		border-radius: 3px;

		.price {
			display: block;
			font-size: 28px;
			text-transform: none;
		}

		.price_multiple_lines {
			margin-bottom: -0.8rem;
		}

		.price_more_info {
			font-family: $font-family-sans-serif;
		}

		.description {
			display: block;
			font-size: 8px;
		}

		img {
			filter: brightness(0) saturate(100%) invert(39%) sepia(48%) saturate(3085%) hue-rotate(146deg) brightness(88%) contrast(93%);
		}

	}

	&:checked + label {
		color: $white !important;
		img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(78deg) brightness(102%) contrast(102%);
        }
	}

	&.error {
		+ label {
			border: 1px solid #d4351c;
		}
	}

}

.donation_buttons input[type='radio'] {
    &:hover + label {
		color: $white;
		img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(78deg) brightness(102%) contrast(102%);
        }
	}
}

.donation_type_buttons input[type='radio'] {
	+ label {
		border-radius: 0px;
		height: auto;
		.price {
			font-size: 1rem;
		}
	}
}

/* Layout used by - Bookplate donations */
.custom-control-inline input[type='radio'] {
	position: absolute;
	opacity: 0;

	+ label {
        @include media-breakpoint-down(sm) {
	        width: 100%;
	        text-align: center;
	    }
	    box-sizing: border-box;
	    display: inline-block;
	    border-radius: 4px;
	    background-color: #f0f0f0;
	    cursor: pointer;
	    padding: 8px 16px;
	    color: #231f20;
	    font-size: 16px;
	    font-weight: bold;
		box-shadow: none;
		border: 1px solid #989494;
	}

	+ label:hover {
		background-color: #d6d6d6;
	}

	/*&:checked + label {
		background-color: #000000;
		color: #ffffff;
	}*/

	&:focus {
		+ label {
			outline: thin dotted;
			outline-offset: -2px;
		}
	}

	&.error {
		+ label {
			border: 1px solid #d4351c;
		}
	}

}

/* Used for making controls inline e.g The donation radio buttons */
.custom-control-inline {
    @include media-breakpoint-down(sm) {

		margin-right: 0;
    }

    margin-right: 1rem;

	&:last-child {
        margin-right: 0;
    }

}

.artist {
    font-weight: 400;
    font-size: x-small;
  }