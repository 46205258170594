
$font-size-base:              1.07rem;
$font-family-sans-serif:      'AvantGardeGothicITCW01B 731069', "Arial", sans-serif; /* Book */
$line-height-base:            1.813 !default;
$h1-font-size:                58px;
$h2-font-size:                48px;
$h3-font-size:                24px;
$h4-font-size:                18px;
$h5-font-size:                16px;
$h6-font-size:                14px;
$headings-font-family:        'AvantGardeGothicITCW01B 731063', "Arial", sans-serif; /* Bold */
$headings-font-weight:        normal;
$headings-line-height:        1.25em;
$btn-font-family:             'AvantGardeGothicITCW01D 731075', "Arial", sans-serif;
$subheadings-font-family:     'AvantGardeGothicITCW01D 731075', "Arial", sans-serif; /*  Demi */
$medium-font-family:          'ITCAvantGardeW04-Medium 1122896', "Arial", sans-serif; /*  Medium */

/* Links */
$link-color:                  #4d7392;
$link-decoration:             none;
$link-hover-color:            #334c60;

/* Media queires */
$screen-xxs:                  320px !default;

/* Colours */
$body-bg:                     #ffffff;
$primary:                     #000000;
$clr-optional:                #797676;
$clr-student:                 #099580;
$clr-student-hover:           #057766;
$clr-family:                  #F23188;
$clr-family-hover:            #c1276c;
$clr-cultural:                #0056B6;
$clr-cultural-hover:          #004491;
$clr-access:                  #7DB0E1;
$clr-access-hover:            #6D9BC7;
$clr-verify:                  #7DB0E1;
$clr-home:                    #7DB0E1;
$clr-home-hover:              #7DB0E1;

/* Button stuff */
$primary-btn-bg:              #87ca72;
$primary-btn-bg-states:       #69bc4d;
$secondary-btn-color-states:  #ffffff;
$secondary-btn-bg:            #f0f0f0;
$secondary-btn-bg-states:     #000000;
