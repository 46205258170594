.block {
	display: block;
}

.optional {
  @extend .small;
  display: inline;
  color: $clr-optional;
	//@extend .label-not-field;
}

.additional-info {
	@extend .small;
	font-family: $font-family-sans-serif;
	text-transform: none;
	line-height: 20px;
}

.bold-font {
	font-family: $subheadings-font-family;
}

.checkbox-label {
	margin-bottom: 0px;
	@extend .label-not-field;
}

.field-hint {
  display: block;
	color: #707070;
	//@extend .label-not-field;
}

/* Used for the field labels */
label {
	@extend .bold-font;
	font-size: 1.2rem;
}

label.error {
  @extend .small;
	color: #D4351C;
  font-weight: normal;
	display: block;
	font-weight: normal;
	font-family: $font-family-sans-serif;
	text-transform: none;
}

.label-not-field {
	font-weight: normal;
	font-family: $font-family-sans-serif;
	text-transform: none;
	font-size: 1rem;
}

.radio-end-of-fieldset {
	margin-bottom: 16px;
}

input[aria-invalid="true"], input.error {
  border-color: #D4351C;
}

.shadow {
	margin-bottom: 30px;
}

/* Turn off spinners on number input types */
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Alert style copied from SLV pattern lab */
.alerts {
    background: #fbd7d8;
    padding: 15px 15px;
	line-height: 20px;
    font-size: 16px;
	margin-bottom: 10px;
}

/* Remove the badge from the bottom right hand corner */
.grecaptcha-badge { visibility: hidden; }

.visual-separator {
  display: inline-block;
  min-width: 1em;
  text-align: center;
}

/* Inserting this collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row. We want this effect on tablets */
.break-flex {
	@include media-breakpoint-only(md) {
  	flex-basis: 100%;
		height: 0;
	}
}

/* Override bootstrap padding to avoid visual clipping */
.form-control {
	padding: .6rem 1ch;
}

/* Make sure rows align with other nested elements that don't have a row */
.no-gutter > [class*='col-'] {
  padding-right: 7.5px;
	padding-left: 7.5px;

	@include media-breakpoint-up(xl) {
		padding-right: 15px;
		padding-left: 15px;
	}	
}

.no-gutter-no-row {
	@include media-breakpoint-up(xl) {
		padding-right: 7.5px;
		padding-left: 7.5px;
	}	
}

.no-padding-col {
    padding-right: 0;
    padding-left: 0;
}

label.multiline-checkbox-radio {
	padding-left: 1.25rem;
	margin-bottom: 0;
}

.multiline-checkbox-radio input {
	margin-left: -1.25rem;
	position: absolute;
	margin-top: 0.55rem;
}

.multiline-checkbox-radio span {
	display: block;
	padding-left: 5px;
	font-weight: normal;
}

/* Used to alert the user that their submission is loading */
.loading-spinner {
	margin-top:20px;
  @include loading-spinner;
}

.loading-spinner-text {
	text-align: center;
	padding-bottom: 70px;
}

.shorter-width {
	width: 180px;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
  	width:100%!important;
  }
  .w-sm-75 {
  	width:75%!important;
  }
  .w-sm-50 {
  	width:50%!important;
  }
  .w-sm-25 {
  	width:25%!important;
  }
  .h-sm-100 {
  	height:100%!important;
  }
  .h-sm-75 {
  	height:75%!important;
  }
  .h-sm-50 {
  	height:50%!important;
  }
  .h-sm-25 {
  	height:25%!important;
  }
}
	
/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
  	width:100%!important;
  }
  .w-md-75 {
  	width:75%!important;
  }
  .w-md-50 {
  	width:50%!important;
  }
  .w-md-25 {
  	width:25%!important;
  }
  .h-md-100 {
  	height:100%!important;
  }
  .h-md-75 {
  	height:75%!important;
  }
  .h-md-50 {
  	height:50%!important;
  }
  .h-md-25 {
  	height:25%!important;
  }
}
	
/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
  	width:100%!important;
  }
  .w-lg-75 {
  	width:75%!important;
  }
  .w-lg-50 {
  	width:50%!important;
  }
  .w-lg-25 {
  	width:25%!important;
  }
  .h-lg-100 {
  	height:100%!important;
  }
  .h-lg-75 {
  	height:75%!important;
  }
  .h-lg-50 {
  	height:50%!important;
  }
  .h-lg-25 {
  	height:25%!important;
  }
}
	
/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
  	width:100%!important;
  }
  .w-xl-75 {
  	width:75%!important;
  }
  .w-xl-50 {
  	width:50%!important;
  }
  .w-xl-25 {
  	width:25%!important;
  }
  .h-xl-100 {
  	height:100%!important;
  }
  .h-xl-75 {
  	height:75%!important;
  }
  .h-xl-50 {
  	height:50%!important;
  }
  .h-xl-25 {
  	height:25%!important;
  }
}
