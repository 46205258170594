/* Logout */
.logout-container {
  button {
    width: auto;

    @include media-breakpoint-up(sm) {
      width: max-content;
    }

    img {
      @include media-breakpoint-up(sm) {
        padding-left: 10px;
      }
    }
  }
}

/* Login page next available */
.next-avail-meeting-block {
  > div:first-child {
    @extend .bold-font;
  }
}

/* jQuery UI date picker */
.ui-datepicker {
  width: 100%;
}

.ui-datepicker td span, .ui-datepicker td a {
  padding: 0.5em;
  text-align: center;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  background: none;
  border: 1px solid #c5c5c5;
}

/* Rooms */

/* Override the bootstrap declaration */
.table thead th {
  border-bottom: none;
}

.select-room {
  th {
    font-family: $headings-font-family;
  }
  td {
    &.room-id {
      font-family: $medium-font-family;
    }
    .room-desc-mobile {
      img {
        border-radius: 50%;
        //padding: 10px;
        max-height: 100%;
        margin-left: 10px;
        
        @include media-breakpoint-up(md) {
          padding: 5px;
        }
      }
    }
  }
}

/* Times */
.time-container {
  border: 1px solid #707070;
  padding: 10px 20px;

  .times-subheading {
    font-size: 1.3rem;
    font-family: $medium-font-family;
    text-align: center;
  }
  
  .times-subheading-smaller {
    font-size: 1rem;
  }

  #bookingTime {
    font-family: $font-family-sans-serif;
    font-size: 1.1rem;

    .bookingTimeRow {
      border-bottom: 1px solid #F0F0F0;
      padding: 10px 5px;

      .extend-booking {
        width: 130px;
      }
    }
  }

  .wheelchair__times {
    gap: 20px;
    margin-bottom: 10px;

    label {
      width: 100px;
    }

  }

}

/* Style for the plus and minus buttons */
.counter-button {
  background-color: #F0F0F0;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  width: 30px;
}

/* Style for the number */
.counter-number {
    //font-size: 24px;
    margin: 0 5px;
}

/* Wheelchair confirm page */
#wheelchair_step3 {
  .detail-description {
    p {
      margin-bottom: 5px;
    }
  }
}
